import { render, staticRenderFns } from "./FormPersonalData.vue?vue&type=template&id=68165f41&scoped=true&"
import script from "./FormPersonalData.vue?vue&type=script&lang=js&"
export * from "./FormPersonalData.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68165f41",
  null
  
)

export default component.exports