<template>
    <div>

        <b-form-group
            :label="label"
            class="mb-0"
            label-class="pt-2"
            label-cols="6"
            label-size=""
        >
            <b-form-select
                v-model="table.selected"
                :options="tableSelectOptions"
                class="float-right"
                style="width: 200px;"
                @change="itemAdd(tableSelectOptions)"
            />
        </b-form-group>


        <!-- table -->
        <b-table
            id="main-table"
            :fields="tableFields"
            :items="table.items"
            bordered
            hover
            outlined
            sticky-header="270px"
            table-class="text-center"
        >
            <!-- header add -->
            <template #head(value_a)="head">
                <div class="d-flex justify-content-center align-items-center font-weight-normal">
                    {{head.field.label}}
                </div>
            </template>
            <template #head(value_b)="head">
                <div class="d-flex justify-content-center align-items-center font-weight-normal">
                    {{head.field.label}}
                </div>
            </template>
            <template #head(value_c)="head">
                <div class="d-flex justify-content-center align-items-center font-weight-normal">
                    {{head.field.label}}
                </div>
            </template>
            <!-- value a -->
            <template v-slot:cell(value_a)="row">
                <b-input v-model="row.item.value_a"></b-input>
            </template>
            <!-- value b -->
            <template v-slot:cell(value_b)="row">
                <div class="d-flex justify-content-center align-items-center">
                    <b-input v-model="row.item.value_b"></b-input>
                </div>
            </template>
            <!-- value c -->
            <template v-slot:cell(value_c)="row">
                <b-input v-model="row.item.value_c" :type="input_c_type"></b-input>
            </template>
            <!-- actions -->
            <template v-slot:cell(action)="row">
                <div class="d-flex justify-content-center align-items-center" style="height:40px">
                    <!-- default -->
                    <b-icon-suit-heart
                        v-if="!row.item.default"
                        v-b-tooltip.hover
                        :title="$t('form.default')"
                        scale="1.2"
                        style="cursor: pointer; margin-right: 3px;"
                        variant="secondary"
                        @click="itemDefault(row.item)"
                    ></b-icon-suit-heart>
                    <b-icon-suit-heart-fill
                        v-if="row.item.default"
                        v-b-tooltip.hover
                        :title="$t('form.default')"
                        scale="1.2"
                        style="cursor: pointer; margin-right: 3px;"
                        variant="secondary"
                        @click="itemDefault(row.item)"
                    ></b-icon-suit-heart-fill>
                    <!-- deprecated -->
                    <b-icon-eye-slash
                        v-if="!row.item.deprecated"
                        v-b-tooltip.hover
                        :title="$t('form.deprecated')"
                        scale="1.2"
                        style="cursor: pointer; margin: 0 3px 0 3px;"
                        variant="secondary"
                        @click="itemDeprecated(row.item)"
                    ></b-icon-eye-slash>
                    <b-icon-eye-slash-fill
                        v-if="row.item.deprecated"
                        v-b-tooltip.hover
                        :title="$t('form.deprecated')"
                        scale="1.2"
                        style="cursor: pointer; margin: 0 3px 0 3px;"
                        variant="secondary"
                        @click="itemDeprecated(row.item)"
                    ></b-icon-eye-slash-fill>
                    <!-- delete -->
                    <b-icon-trash
                        v-if="!row.item.system"
                        v-b-tooltip.hover
                        :title="$t('form.delete')"
                        scale="1.2"
                        style="cursor: pointer; margin-left: 3px;"
                        variant="danger"
                        @click="itemDelete(row.item)"
                    ></b-icon-trash>
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
export default {
    name: "FormTableValuesItems",
    components: {},
    props: {
        extended: {
            type: Boolean,
            default() {
                return false
            }
        },
        json_key: {
            type: String,
            default() {
                return 'table_values'
            }
        },
        label: {
            type: String,
            default() {
                return 'Label'
            }
        },
        column_a_text: {
            type: String,
            default() {
                return 'Column A'
            }
        },
        column_b_text: {
            type: String,
            default() {
                return 'Column B'
            }
        },
        column_c_text: {
            type: String,
            default() {
                return 'Column C'
            }
        },
        select_add_text: {
            type: String,
            default() {
                return this.$t('form.add')
            }
        },
        select_other_text: {
            type: String,
            default() {
                return this.$t('form.other')
            }
        },
        select_options: {
            type: Array,
            default() {
                return []
            }
        },
        input_c_type: {
            type: String,
            default() {
                return 'text'
            }
        },
        record: {},
    },
    data() {
        return {
            table: {
                items: [],
                fields: [
                    {
                        key: 'value_a',
                        label: this.column_a_text,
                        sortable: false,
                        extended: true,
                        // thStyle: {width: '33%'}
                    },
                    {
                        key: 'value_b',
                        label: this.column_b_text,
                        sortable: false,
                        // thStyle: {width: '33%'}
                    },
                    {
                        key: 'value_c',
                        label: this.column_c_text,
                        sortable: false,
                        // thStyle: {width: '33%'}
                    },
                    {
                        key: 'action',
                        label: '',
                        sortable: false,
                        thStyle: {width: '100px'}
                    },
                ],
                selected: null,
            },
        }
    },
    mounted() {
    },
    methods: {
        // <-- table items -->
        itemAdd() {
            let options = this.tableSelectOptions;
            // <-- find option -->
            let option = options.find((n) => {
                if (n.value === this.table.selected) {
                    return n;
                }
            });
            // <--  -->
            let index = this.table.items.length;
            this.table.items.push(
                {
                    key: this.__createUniqueKey(index),
                    value_a: '',
                    value_b: option.value === 'other' ? '' : option.text,
                    value_c: '',
                    default: false,
                    deprecated: false
                }
            );
            // <--  -->
            this.table.selected = null;
        },
        itemDelete(row) {
            let items = this.table.items;
            let key = row.key;
            // <-- find row index -->
            let index = -1;

            items.find((n, indexTemp) => {
                if (n.key === key) {
                    index = indexTemp;
                }
            });
            // <-- remove item -->
            this.table.items.splice(index, 1);
        },
        itemDefault(row) {
            let items = this.table.items;
            let key = row.key;
            let valueOld;
            // <-- find row index -->
            items.find((n, index) => {
                valueOld = this.table.items[index].default;
                this.table.items[index].default = false;
                if (n.key === key) {
                    this.table.items[index].default = !valueOld;
                }
            });
            this.itemsOrder();
        },
        itemDeprecated(row) {
            let items = this.table.items;
            let key = row.key;
            let valueOld;
            // <-- find row index -->
            items.find((n, index) => {
                valueOld = this.table.items[index].deprecated;
                if (n.key === key) {
                    this.table.items[index].deprecated = !valueOld;
                }
            });
        },
        itemsOrder() {
            let items = [];
            // <-- default item -->
            this.table.items.forEach(item => {
                if (item.default) {
                    items.push(item);
                }
            });
            // <-- non default item -->
            this.table.items.forEach(item => {
                if (!item.default) {
                    items.push(item);
                }
            });
            // <--  -->
            this.$set(this.table, 'items', items);
        },
        // <-- record -->
        setRecords() {
            let records = [];
            this.table.items.forEach((item, index) => {
                records.push({
                    index: index,
                    value_a: item.value_a,
                    value_b: item.value_b,
                    value_c: item.value_c,
                    default: item.default,
                    deprecated: item.deprecated,
                });
            });
            // <--  -->
            this.$set(this.record, this.json_key, records);
        },
    },
    computed: {
        tableSelectOptions() {
            let options = [];
            // <-- option add -->
            if (!this.__isNull(this.select_add_text)) {
                options.push({
                    value: null,
                    text: this.select_add_text,
                    disabled: true
                });
                options.push({
                    value: -1, text: '  ----------------', disabled: true
                });
            }
            // <-- options -->
            this.select_options.forEach(option => {
                options.push({
                    value: option.value,
                    text: option.text,
                });
            });
            // <-- option other -->
            if (!this.__isNull(this.select_other_text)) {
                options.push({
                    value: 'other',
                    text: this.select_other_text
                });
            }
            // <--  -->
            return options;
        },
        tableFields() {
            let fields = [];
            this.table.fields.forEach(field => {
                if (this.extended || field.extended === undefined || field.extended === false) {
                    fields.push(field);
                }
            });
            return fields;
        },
    },
    filters: {},
    watch: {
        'table.items': {
            handler: function (value, oldValue) {
                this.setRecords();
            },
            deep: true
        },
    }
}
</script>

<style scoped>

</style>
