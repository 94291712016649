<template>
    <div>
        <!-- state -->
        <b-form-group
            :label="$t('address.state')"
            class="mb-2 mr-sm-2 mb-sm-0"
            description=""
            label-class="mb-0"
            label-for="address.state"
        >
            <b-form-select
                id="address.state"
                v-model="record.state_id"
                :options="getStates"
                class="mb-2 mr-sm-2 mb-sm-0"
            ></b-form-select>
        </b-form-group>
        <!-- select address -->
        <div v-if="record.state_id > 0">
            <!-- street -->
            <b-form-group
                v-model="record.street"
                :label="$t('address.street')"
                class="mb-2 mr-sm-2 mb-sm-0 pt-2"
                description=""
                label-class="mb-0"
                label-for="street"
            >
                <b-form-input
                    id="street"
                    :formatter="__capitalize"
                    v-model="record.street"
                ></b-form-input>
            </b-form-group>
            <!-- post office -->
            <b-form-group
                :label="$t('address.post_office')"
                class="mb-2 mr-sm-2 mb-sm-0 pt-2"
                description=""
                label-class="mb-0"
                label-for="address.post_office"
            >
                <b-form-select
                    id="address.post_office"
                    v-model="record.postOffice_id"
                    :options="getPostOffices"
                    class="mb-2 mr-sm-2 mb-sm-0"
                ></b-form-select>
            </b-form-group>
            <!-- region -->
            <b-form-group
                :label="$t('address.region')"
                class="mb-2 mr-sm-2 mb-sm-0 pt-2"
                description=""
                label-class="mb-0"
                label-for="address.region"
            >
                <b-form-select
                    id="address.region"
                    v-model="record.region_id"
                    :options="getRegions"
                    class="mb-2 mr-sm-2 mb-sm-0"
                ></b-form-select>
            </b-form-group>
        </div>
        <!-- write full address -->
        <div v-if="record.state_id < 0">
            <b-form-group
                :label="$t('address.address')"
                class="mb-2 mr-sm-2 mb-sm-0 pt-2"
                description=""
                label-class="mb-0"
                label-for="address.street"
            >
                <b-form-textarea
                    id="profile_address_other"
                    v-model="record.full"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    rows="3"
                ></b-form-textarea>
                <!--                    :placeholder="$t('address.full_address_placeholder')"-->
            </b-form-group>
        </div>
    </div>
</template>

<script>
export default {
    name: "FormAddress",
    components: {},
    props: {
        record: {
            type: Object,
            default() {
                return {}
            }
        },
        data: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data() {
        return {}
    },
    mounted() {},
    methods: {},
    computed: {
        getStates() {
            let array = [];
            // <-- choose -->
            array.push({
                value: null,
                text: this.$t('form.choose_placeholder')
            });
            // <-- states -->
            let text = null;
            this.data.forEach(state => {
                text = state.meta.name;
                if (state.locale[this.$i18n.locale] !== undefined) {
                    text = state.locale[this.$i18n.locale].name;
                }
                array.push({
                    value: state.id,
                    text: text
                });
            });
            // <-- other -->
            array.push({
                value: -1,
                text: this.$t('form.other')
            });
            // <--  -->
            return array;
        },
        getPostOffices() {
            let array = [];
            // <-- choose -->
            array.push({
                value: null,
                text: this.$t('form.choose_placeholder')
            });
            // <--  -->
            let text = null;
            // <-- get select state -->
            let state = this.data.find((n) => {
                if (n.id === this.record.state_id) {
                    return n;
                }
            });
            if (state === undefined) {
                return array;
            }
            // <-- post offices -->
            state.post_office.forEach(postOffice => {
                array.push({
                    value: postOffice.id,
                    text: postOffice.post_code + ' ' + postOffice.city
                });
            });
            // <--  -->
            return array;
        },
        getRegions() {
            let array = [];
            // <-- choose -->
            array.push({
                value: null,
                text: this.$t('form.choose_placeholder')
            });
            // <-- get select state -->
            let state = this.data.find((n) => {
                if (n.id === this.record.state_id) {
                    return n;
                }
            });
            if (state === undefined) {
                return array;
            }
            // <-- region -->
            let text = null;
            state.region.forEach(region => {
                text = region.meta.name;
                array.push({
                    value: region.id,
                    text: text
                });
            });
            // <--  -->
            return array;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
