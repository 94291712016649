<template>
    <div v-if="Object.keys(this.record).length > 0">
        <b-row>
            <b-form-group
                :label="$t('user.personal_data')"
                class="mb-0 ml-3"
                label-class="font-weight-bold pt-0"
                label-size="md"
            >
            </b-form-group>
        </b-row>
        <b-row>
            <!--col 1-->
            <b-col>
                <b-form-group
                    class="mb-0"
                    label-class="font-weight-bold pt-0"
                    label-size="md"
                >
                    <!-- name -->
                    <b-form-group
                        :label="$t('user.name')"
                        class="mb-2 mr-sm-2 mb-sm-0"
                        description=""
                        label-class="mb-0"
                        label-for="user.name"
                    >
                        <b-form-input
                            id="user.name"
                            v-model="record.name.name"
                            :formatter="__capitalize"
                        ></b-form-input>
                    </b-form-group>
                    <!-- surname -->
                    <b-form-group
                        :label="$t('user.surname')"
                        class="mb-2 mr-sm-2 mb-sm-0 pt-2"
                        description=""
                        label-class="mb-0"
                        label-for="user.surname"
                    >
                        <b-form-input
                            id="user.surname"
                            v-model="record.name.surname"
                            :formatter="__capitalize"
                        ></b-form-input>
                    </b-form-group>
                    <!-- address -->
                    <b-form-group
                        :label="$t('address.address')"
                        class="mb-0"
                        label-class="font-weight-bold pt-2"
                        label-size="md"
                    >
                        <form-address
                            :data="data.states"
                            :record.sync="getRecordAddress"
                        ></form-address>
                    </b-form-group>
                </b-form-group>
            </b-col>
            <!--col 2-->
            <b-col>
                <!-- phones -->
                <b-form-group
                    :label="$t('user.phone')"
                    class="mb-2 mr-sm-2 mb-sm-0 pt-0"
                    description=""
                    label-class="mb-0"
                    label-for="personal_data.phone"
                >
                    <form-table-values
                        id="personal_data.phone"
                        :column_a_text="$t('form.table_values.person')"
                        :column_b_text="$t('form.table_values.description')"
                        :column_c_text="$t('form.table_values.number')"
                        :extended="false"
                        :record="recordClone.contact"
                        input_c_type="tel"
                        json_key="phone"
                        @eventUpdateRecord="contactUpdate"
                    />
                </b-form-group>
                <!-- internet contacts -->
                <b-form-group
                    :label="$t('user.internet_contact')"
                    class="mb-2 mr-sm-2 mb-sm-0 pt-2"
                    description=""
                    label-class="mb-0"
                    label-for="personal_data.socialMedia"
                >
                    <form-table-values
                        id="personal_data.socialMedia"
                        :column_a_text="$t('form.table_values.person')"
                        :column_b_text="$t('form.table_values.description')"
                        :column_c_text="$t('form.table_values.address')"
                        :extended="false"
                        :record="recordClone.contact"
                        input_c_type="text"
                        json_key="internet_contact"
                        @eventUpdateRecord="contactUpdate"
                    />
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import FormTableValues from "@/components/general/elements/formElements/FormTableValues";
import FormTableValuesItems from "@/components/general/elements/formElements/FormTableValuesItems";
import FormAddress from "@/components/general/elements/formElements/FormAddress";

export default {
    name: "FormPersonalData",
    components: {FormAddress, FormTableValuesItems, FormTableValues},
    props: {
        data: {
            type: Object,
            default() {
                return {}
            }
        },
        recordDefault: {
            type: Object,
            default() {
                return {}
            }
        },
        record: {
            type: Object,
            default() {
                return {}
            }
        },
        recordClone: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    data() {
        return {}
    },
    mounted() {
    },
    methods: {
        contactUpdate(data){
            this.$set(this.record.contact, data.key, data.values);
        }
    },
    computed: {
        getRecordAddress() {
            // <-- new record -->
            if (this.record.id === undefined) {
                return this.record.address;
            }
            // <-- edit record -->
            let record = this.record;
            let address = this.__clone(this.recordDefault.address);

            address.state_id = record.post_office === null ? record.address.state_id : record.post_office.state_id;
            address.postOffice_id = record.post_office_id;
            address.region_id = record.region_id;
            address.street = record.address.street;
            address.full= record.address.full;
            // <--  -->
            this.$set(this.record, 'address', address);
            return this.record.address;
        }
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
